import request from 'common/utils/requests'
import type { Order, ChoiceActionParams, ChoiceActionRes } from '@/types/order'

/** 获取订单信息 */
export const getOrderInfo = () => request.get<null, Order>(
  `/merchant/checkout/counter`
)

/** 获取二维码 */
export const getQR = () => request.get<null, { qrcode: string }>(
  `/merchant/checkout/counter/qrpic`,
)

//  Choice事件接口
export const choiceAction = (data: ChoiceActionParams) => request.post<ChoiceActionParams, ChoiceActionRes>(
  `/merchant/checkout/counter/handle/action`,
  data
)