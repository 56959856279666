import type { AppRouteRecordRaw } from 'common/router/type'
import { getDeviceType, openAppByUrl } from 'common/utils/system'
import { useTimer } from '@/hooks/useTimer'
import useOrderStore from '@/store/order'
import type { IndiaQr } from '@/types/indiaQr'
import { computed, reactive, ref } from 'vue'
import { indiaQrBindUpi } from '@/api/indiaQr'
import { modalAlert } from '@/utils/modal'
import useAppStore from '@/store/app'
import { env } from 'common/utils/env'
import { get } from 'lodash-es'

export const route: AppRouteRecordRaw = {
  path: `/pnbPay`,
  component: () => getDeviceType() === 'mobile' ? import('./mobile.vue') : import('./pc.vue'),
}

export const usePageHook = () => {
  const orderStore = useOrderStore()
  /** 获取银行logo */
  const getBankLogo = (name: string) => {
    if (env.isUseVite) {
      return new URL(`../../../assets/bank/${name}.png`, import.meta.url).href
    } else {
      return require(`../../../assets/bank/${name}.png`)
    }
  }

  type BankApp = { logo: string; name: string; link: string; }
  const appState = reactive({
    currentApp: null as BankApp | null,
    currentIndex: -1,
    appList: [
      { logo: getBankLogo('paytm'), name: 'Paytm', link: 'paytmmp://pay' },
      { logo: getBankLogo('phonePe'), name: 'PhonePe', link: 'paytmmp://pay' },
      { logo: getBankLogo('gPay1'), name: 'GPay', link: 'gpay://upi/pay', },
      { logo: getBankLogo('mobiKwik2'), name: 'MobiKwik', link: 'mobikwik://open' },
      { logo: getBankLogo('upi'), name: 'UPI', link: 'upi://pay', },
    ] as BankApp[],
  })

  const loading = ref(false)
  const openApp = (app: BankApp, index: number) => {
    appState.currentApp = app
    appState.currentIndex = index
    loading.value = true
    setTimeout(() => {
      loading.value = false
    }, 5000)
    // 打开App
    openAppByUrl(`${app.link}?${get(orderInfo.value, 'extra_param.extra_info.cstxt')}`)
  }
  const appStore = useAppStore()
  const siteLogo = computed(() => appStore.config.site_logo)

  const formState = reactive({
    upi: '',
    utr: '',
  } as IndiaQr)
  /** 提交UPI */
  const submitUpi = () => {
    if (!formState.upi.includes('@')) {
      modalAlert({
        type: 'warning',
        content: `Please enter UPI in the correct format`,
      })
    } else {
      indiaQrBindUpi({ upi: formState.upi }).then((res) => {
        if (res.upi === formState.upi) {
          endExpiredTimer()
          startLoopTimer(orderStore.getInfo)
        }
      })
    }
  }

  const { orderInfo, startLoopTimer, endExpiredTimer, expiredCountdown, expiredDatetime } = useTimer()

  const init = () => {
    startLoopTimer(orderStore.getInfo)
  }

  return {
    appState,
    openApp,
    loading,
    siteLogo,
    orderInfo,
    init,
    formState,
    submitUpi,
    expiredCountdown,
    expiredDatetime,
  }
}