import type { AppRouteRecordRaw } from 'common/router/type'
import { getDeviceType } from 'common/utils/system'
// import { useTimer } from '@/hooks/useTimer'
import { useTimer } from '@/hooks/customTimer'

import useOrderStore from '@/store/order'


export const route: AppRouteRecordRaw = {
  path: `/PTMPAY`,
  component: () =>
    getDeviceType() === 'mobile' ? import('./mobile.vue') : import('./pc.vue')
}

export const usePageHook = () => {
  const orderStore = useOrderStore()
  const { orderInfo, startLoopTimer, endExpiredTimer } = useTimer()
  const init = () => {
    startLoopTimer(orderStore.getInfo)
  }

  return {
    orderInfo,
    init
  }
}
