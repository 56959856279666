import type { AppRouteRecordRaw } from 'common/router/type'
import { getDeviceType } from 'common/utils/system'
// import { useTimer } from '@/hooks/useTimer'
import { useTimer } from '@/hooks/customTimer'

import useOrderStore from '@/store/order'
import { ref, computed } from 'vue'
import useAppStore from '@/store/app'
import { choiceAction } from '@/api/choice'

import { message } from 'ant-design-vue'

export const route: AppRouteRecordRaw = {
  path: `/usdtbuy`,
  component: () =>
    getDeviceType() === 'mobile' ? import('./mobile.vue') : import('./pc.vue')
}

export const usePageHook = () => {
  const orderStore = useOrderStore()
  const { orderInfo, bankList, startLoopTimer, endExpiredTimer } = useTimer()
  const appStore = useAppStore()

  // 获取 Config 配置
  const useUpstreamApi = computed(() => appStore.config.use_upstream_api)
  const transit = computed(() => appStore.config.transit_choice)
  const transitChoice = ref()

  // 选择支付的方法名
  const dynamic1 = ref()
  const moreItemInfo = ref({})
  const choiceLoading = ref<boolean>(false)
  const choiceList = ref([] as any[])
  const accountTypeChain = ref()
  /** 选择支付的方法名 */
  const clickMoreInfo = (item: any, index: any, loc: string) => {
    dynamic1.value = index
    if (useUpstreamApi.value === 1) {
      getAgentInfo(item.payment_method)
    } else {
      getChoiceInfo(item.payment_method)
    }
  }
  /** 获取支付方式列表(选择支付方式调用) 接口开关为1 */
  const getAgentInfo = (choice: any | null) => {
    choiceLoading.value = true
    const data = {
      action: 'finance_choices',
      data: {
        pay_type: choice,
        currency: bankList.value[0].bank_code
      }
    }
    choiceAction(data).then(res => {
      if (transitChoice.value.length > 0) {
        choiceList.value = res.data.filter(
          (item: any) => transitChoice.value.indexOf(item.name) !== -1
        )
      } else {
        choiceList.value = res.data
      }
      clickItem(choiceList.value[0], 0)
      choiceLoading.value = false
    })
  }

  /** 获取支付方式列表(选择支付方式调用) 接口开关为0 */
  const getChoiceInfo = (choice: any | null) => {
    choiceLoading.value = true
    fetch('https://api.openc.pro/widget/list', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        widgetId: 'UjcyR3hQVlk'
      },
      referrer: '',
      body: JSON.stringify({
        chain: accountTypeChain.value,
        cryptCoin: 'USDT',
        currencyAmt: orderInfo.value.amount,
        currencyCoin: orderInfo.value.bank[0].bank_code,
        payType: choice
      })
    })
      .then(response => response.json())
      .then(res => {
        if (transitChoice.value.length > 0) {
          choiceList.value = res.data.filter(
            (item: any) => transitChoice.value.indexOf(item.name) !== -1
          )
        } else {
          choiceList.value = res.data
        }
        clickItem(choiceList.value[0], 0)
        choiceLoading.value = false
      })
  }

  const dynamic = ref()
  const chiceItemInfo = ref({
    name: '',
    quotePrice: null,
    quoteUnit: null,
    quote_price: null,
    receivedAmt: null,
    receive_coin: null,
    receivedUnit: null,
    channelId: null,
    channel_id: null,
    currencyAmt: null,
    cryptoCoin: null,
    payType: null,
    pay_type: null
  })
  const clickItem = (item: any, index: any) => {
    chiceItemInfo.value = item
    dynamic.value = index
  }

  const init = () => {
    startLoopTimer(orderStore.getInfo)
    dynamic1.value = null
    if (transitChoice.value) {
      transitChoice.value = transit
    } else {
      transitChoice.value = []
    }

    if (orderInfo.value.extra_param.account_type === 'trc20') {
      accountTypeChain.value = 'Tron'
    } else {
      accountTypeChain.value = 'Ethereum'
    }
  }

  const choicePay = () => {
    fetch('https://api.openc.pro/widget/placeOrder', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        widgetId: 'UjcyR3hQVlk'
      },
      referrer: '',
      body: JSON.stringify({
        channelId: chiceItemInfo.value.channelId,
        currencyAmt: chiceItemInfo.value.currencyAmt,
        cryptCoin: chiceItemInfo.value.cryptoCoin,
        currencyCoin: chiceItemInfo.value.quoteUnit,
        cryptoCoin: chiceItemInfo.value.cryptoCoin,
        payType: chiceItemInfo.value.payType,
        chain: accountTypeChain.value,
        walletAddress: orderInfo.value.extra_param.qrcode_text
      })
    })
      .then(response => response.json())
      .then(res => {
        window.location.href = res.data.redirectUrl
        if (res.data.redirectUrl) {
          choiceLoading.value = false
        }
      })
      .catch(err => {
        message.info(
          `<div style="color: red;font-weight: 500;">
            <p style="text-align:left">Your region is not supported.</p></br>
            <div style="text-align:left">
              Thank you for your interest in using our platform. Unfortunately, we are currently unable to offer our services in your region but are actively working to make this happen.
              </div>
            </div>`
        )
      })
  }
  const choiceAgentPay = () => {
    choiceLoading.value = true
    const data: any = {
      action: 'apply_order',
      data: {
        channel_id: chiceItemInfo.value.channel_id,
        pay_type: chiceItemInfo.value.pay_type,
        currency: 'USD',
        send_choice_data: '1'
      }
    }
    choiceAction(data).then((res: any) => {
      if (res.url) {
        window.location.href = res.url
      } else {
        message.warning('Unable to open payment page，please wait')
      }
      choiceLoading.value = false
    })
  }

  return {
    orderInfo,
    init,
    dynamic1,
    dynamic,
    chiceItemInfo,
    moreItemInfo,
    choiceLoading,
    clickMoreInfo,
    clickItem,
    choiceList,
    accountTypeChain
    // choicePay,
    // choiceAgentPay
  }
}
