/**
 * @description 获取操作系统类型
 * @returns
 */
export const browserRedirect = () => {
  const sUserAgent = navigator.userAgent
  const isWin = navigator.platform == 'Win32' || navigator.platform == 'Windows'
  const isMac =
    navigator.platform == 'Mac68K' ||
    navigator.platform == 'MacPPC' ||
    navigator.platform == 'Macintosh' ||
    navigator.platform == 'MacIntel'
  if (isMac) return 'Mac'
  const isUnix = navigator.platform == 'X11' && !isWin && !isMac
  if (isUnix) return 'Unix'
  const isLinux = String(navigator.platform).indexOf('Linux') > -1
  if (isLinux) return 'Linux'
  if (isWin) {
    const isWin2K =
      sUserAgent.indexOf('Windows NT 5.0') > -1 || sUserAgent.indexOf('Windows 2000') > -1
    if (isWin2K) return 'Win2000'
    const isWinXP =
      sUserAgent.indexOf('Windows NT 5.1') > -1 || sUserAgent.indexOf('Windows XP') > -1
    if (isWinXP) return 'WinXP'
    const isWin2003 =
      sUserAgent.indexOf('Windows NT 5.2') > -1 || sUserAgent.indexOf('Windows 2003') > -1
    if (isWin2003) return 'Win2003'
    const isWinVista =
      sUserAgent.indexOf('Windows NT 6.0') > -1 || sUserAgent.indexOf('Windows Vista') > -1
    if (isWinVista) return 'WinVista'
    const isWin7 = sUserAgent.indexOf('Windows NT 6.1') > -1 || sUserAgent.indexOf('Windows 7') > -1
    if (isWin7) return 'Win7'
    const isWin10 =
      sUserAgent.indexOf('Windows NT 10') > -1 || sUserAgent.indexOf('Windows 10') > -1
    if (isWin10) return 'Win10'
  }
  return 'other'
}

/**
 * 获取设备类型
 */
export const getDeviceType = (): 'mobile' | 'pc' => {
  const userAgent = navigator.userAgent
  const isMobile = !userAgent || /Mobile|Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini/i.test(userAgent)
  return isMobile ? 'mobile' : 'pc'
}

/**
 * 根据Url打开外部App
 * @param url 
 */
export const openAppByUrl = (url: string) => {
  const appLinkDom = document.createElement('a')
  appLinkDom.setAttribute('href', url)
  appLinkDom.click()
  appLinkDom.remove()
}