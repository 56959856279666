import { defineStore } from 'pinia'
import type { Order } from '@/types/order'
import { getOrderInfo } from '@/api/order'
import { get } from 'lodash-es'

const storeKey = 'order'
export default defineStore(storeKey, {
  state: () => {
    return {
      info: {
        ordernumber: '',
        amount: '',
        is_expired: false,
        status: 0,
        extra_param: {
          qrcode: '',
        },
      } as Order,
    }
  },
  getters: {
    expiredText(state) {
      return get(state.info, 'extra_param.chat_message.expire_message')
    },
    successText(state) {
      return get(state.info, 'extra_param.chat_message.payment_success_message')
    },
    jumpText(state) {
      return get(state.info, 'extra_param.chat_message.jump_message', '')
    },
  },
  actions: {
    getInfo() {
      return new Promise<Order>((resolve) => {
        getOrderInfo().then(res => {
          if (res) {
            this.info = res
          }
          resolve(this.info)
        })
      })
    }
  }
})