import type { AppRouteRecordRaw } from 'common/router/type'
import { getDeviceType } from 'common/utils/system'
import { useTimer } from '@/hooks/useTimer'
import useOrderStore from '@/store/order'
import { getQR } from '@/api/order'
import { env } from 'common/utils/env'
import { ref } from 'vue'

export const route: AppRouteRecordRaw = {
  path: `/metaPay`,
  component: () => getDeviceType() === 'mobile' ? import('./mobile.vue') : import('./pc.vue'),
}

export const usePageHook = () => {
  const orderStore = useOrderStore()
  /** 获取银行logo */
  const getBankLogo = (name: string) => {
    if (env.isUseVite) {
      return new URL(`../../../assets/bank/${name}.png`, import.meta.url).href
    } else {
      return require(`../../../assets/bank/${name}.png`)
    }
  }
  const bankList = [
    getBankLogo(`paytm`),
    getBankLogo(`phonePe`),
    getBankLogo(`gPay1`),
    getBankLogo(`bhim`),
    getBankLogo(`india`),
    getBankLogo(`indiaM`),
    getBankLogo(`indiaF`),
  ]

  const qrCode = ref<string>()
  /** 获取二维码 */
  const getQrCode = () => {
    getQR().then(res => {
      qrCode.value = `data:image/png;base64,${res.qrcode}`
    })
  }

  const { orderInfo, startLoopTimer, endExpiredTimer } = useTimer()

  const init = () => {
    startLoopTimer(orderStore.getInfo)
    if (getDeviceType() === 'pc') {
      getQrCode()
    }
  }

  return {
    getBankLogo,
    orderInfo,
    init,
    bankList,
    qrCode,
  }
}