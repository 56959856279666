import request from 'common/utils/requests'
import type { IndiaQrParams } from '@/types/indiaQr'

/** indiaQr绑定UTR */
export const indiaQrBindUtr = (data: IndiaQrParams) => request.patch<IndiaQrParams>(
  `/merchant/checkout/counter/check/upi/paid`,
  data
)

/** indiaQr绑定UPI */
export const indiaQrBindUpi = (data: IndiaQrParams) => request.patch<IndiaQrParams, { upi: string; }>(
  `/merchant/checkout/counter/qr/bind/upi`,
  data
)