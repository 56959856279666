import axios from 'axios'
import { getToken } from './cookies'
import { getBaseApiUrl } from './config'
import { modalAlert } from '@/utils/modal'
import router from 'common/router'
import { get } from 'lodash-es'
import { useRoute } from 'vue-router'

// 获取APIBaseUrl
export const APP_BASE_URL = getBaseApiUrl()

// 是否显示重新登录
export const isRelogin = { show: false }

const timeout = 15 * 1000
const http = axios.create({
  baseURL: APP_BASE_URL,
  timeout: timeout
})

http.interceptors.request.use(
  async (config) => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
      config.headers!['Authorization'] = 'Token ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

http.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code: number = res.data.code || 200
    // 获取错误信息
    const msg = res.data.message || ''
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res.data.data || res.data
    }
    if (code === 401) {
      router.replace({ name: 'expired' })
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
      return Promise.reject(new Error(msg))
    } else if (code !== 200) {
      if (res.config.url === '/merchant/checkout/counter/h5/getvpa' && code === 201) {
        modalAlert({
          type: 'warning',
          content: msg,
          onOk: () => {
            window.location.reload()
          }
        })
      }
      return Promise.reject(`${code}, ${res.config.url}, ${msg}`)
    } else {
      return res.data
    }
  },
  (error) => {
    const { message: msg, response: res } = error
    const _code = get(res, 'data.code', 500)
    const errMsg = `请求异常，状态码：${_code}, url：${error.config.url}`
    if (error.config.url === '/merchant/cc-event') {
      return Promise.reject(errMsg)
    }
    if (_code === 401) {
      router.replace({ name: 'expired' })
      return Promise.reject(`401订单过期`)
    } else {
      let _msg = msg
      if (msg == 'Network Error') {
        _msg = 'Internal Server Error'
      } else if (msg.includes('timeout')) {
        _msg = 'System interface request timeout'
      } else if (_code === 400) {
        _msg = res.data.message
      }
      modalAlert({
        type: 'warning',
        content: _msg,
      })
      return Promise.reject(errMsg)
    }
  }
)

export default http
