import { env } from './env'

/**
 * 根据运行环境获取接口域名
 * @returns
 */
export const getBaseApiUrl = (): string => {
  const hostname = window.location.hostname
  const http = window.location.protocol
  const baseURL = env.isBuild
    ? `${http}//api${hostname.substring(hostname.indexOf('.'))}/api`
    : `${env.VUE_APP_BASE_API}`
  // 格式 https://api.xxxxx.com/api
  return baseURL
}
