import type { AppRouteRecordRaw } from 'common/router/type'
import type { Router } from 'vue-router'
import { mapKeys } from 'lodash-es'

/**
 * 静态路由
 */
const defaultRoutes: AppRouteRecordRaw[] = [
  {
    path: '/expired',
    name: 'expired',
    component: () => import('@/pages/results/expired.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('@/pages/results/success.vue')
  },
]
/**
 * 注入静态路由
 * @param router 
 */
export const setStaticRoute = (router: Router) => {
  defaultRoutes.forEach(route => {
    router.addRoute(route)
  })
}



// 获取页面组件
const getDynamicPageModules = () => {
  try {
    return import.meta.glob(
      `@/pages/product/**/index.ts`,
      { eager: true, import: 'route' },
    )
  } catch (error) {
    const res: Record<string, any> = {}
    const ctx = require.context(`@/pages/product`, true, /index\.ts$/)
    ctx.keys().forEach((key) => (res[key] = ctx(key)['route']))
    return res
  }
}
const dynamicPageModuleMap = mapKeys(getDynamicPageModules(),
  (value, key) => {
    return key.replace(`/src/@/pages/product/`, '').replace(`/index.ts`, ``).replace(`./`, ``)
  }
)
const setDynamicRoute = (router: Router) => {
  Object.entries(dynamicPageModuleMap).forEach(config => {
    const _name = config[0]
    const _route = config[1] as AppRouteRecordRaw
    router.addRoute({
      name: _name,
      path: _route.path,
      component: _route.component,
    } as AppRouteRecordRaw)
  })
}

export const initRouter = (router: Router) => {
  setStaticRoute(router)
  setDynamicRoute(router)
}