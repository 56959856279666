import { defineStore } from 'pinia'
import type { AppConfig } from '@/types/app'
import { getAppInfo } from '@/api/app'
import { setToken } from 'common/utils/cookies'
import dayjs from 'dayjs'
import router from 'common/router'

const storeKey = 'app'
export default defineStore(storeKey, {
  state: () => {
    return {
      config: {
        return_url: '',
        use_upstream_api: 0
      } as AppConfig
    }
  },
  getters: {
    returnUrl (state) {
      return state.config.return_url || ''
    }
  },
  actions: {
    init (token?: string, language = 'en') {
      if (language !== 'en') {
        dayjs.tz.setDefault('Asia/Bangkok')
      }
      if (token) {
        setToken(token)
        getAppInfo()
          .then(res => {
            if (res) {
              this.config = res
            }
          })
          .catch(() => {
            router.replace({ name: 'expired' })
          })
      }
    }
  },
  persist: [
    {
      storage: sessionStorage,
      paths: ['config']
    }
  ]
})
