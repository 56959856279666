/** 监控工具 */
import { browserTracingIntegration, init, replayIntegration } from '@sentry/vue'
import type { App } from 'vue'
import { env } from './env'
import { Router } from 'vue-router'

export const sentryInit = (app: App, router: Router) => {
  if (env.openSentry) {
    init({
      app,
      dsn: env.VUE_APP_SENTRY_DSN,
      integrations: [
        browserTracingIntegration({
          router: router,
        }),
        replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      environment: env.NODE_ENV,
      release: `aisipay-checkout-counter@1.0.0`,
      // 性能监控
      tracesSampleRate: 1.0, //  捕获100%的处理
      // Session Replay
      replaysSessionSampleRate: 0.001, // 这将采样率设置为1%。您可能希望在开发时将其更改为100%，然后在生产中以较低的速率进行采样。
      replaysOnErrorSampleRate: 1.0, // 如果尚未对整个会话进行采样，请在对出现错误的会话进行采样时将采样率更改为100%。
    })
  }
}