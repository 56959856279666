import request from 'common/utils/requests'
import type { IndiaH5VPA, IndiaH5Params } from '@/types/indiaH5'

/** 获取VPA */
export const getIndiaH5VPA = () => request.get<null, IndiaH5VPA>(
  `/merchant/checkout/counter/h5/getvpa`,
)

/** 绑定UTR */
export const indiaH5BindUtr = (data: IndiaH5Params) => request.patch<IndiaH5Params>(
  `/merchant/checkout/counter/check/upi/paid`,
  data
)