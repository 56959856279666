// import 'common/utils/arms'
import 'common/styles/index.scss'
import '@/styles/index.scss'
import { initDayjsUtils } from 'common/utils/datetime'
import store from 'common/store'
import router from 'common/router'
import { createApp } from 'vue'
import App from './app.vue'
import { sentryInit } from 'common/utils/sentry'
const app = createApp(App)

sentryInit(app, router)

initDayjsUtils()

app.use(store)
  .use(router)
app.mount('#app')
