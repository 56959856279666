import type { AppRouteRecordRaw } from 'common/router/type'
import { getDeviceType } from 'common/utils/system'
import { useTimer } from '@/hooks/useTimer'
import type { IndiaH5 } from '@/types/indiaH5'
import useOrderStore from '@/store/order'
import { reactive, ref } from 'vue'
import { indiaH5BindUtr } from '@/api/indiaH5'
import { modalAlert } from '@/utils/modal'

export const route: AppRouteRecordRaw = {
  path: `/otherInrqr`,
  component: () => getDeviceType() === 'mobile' ? import('./mobile.vue') : import('./pc.vue'),
}

export const usePageHook = () => {
  const orderStore = useOrderStore()

  const formState = reactive({
    utr: '',
  } as IndiaH5)
  const onUtrInput = () => {
    formState.utr = formState.utr.replace(/[^\d]/g, '')
  }
  /** 提交UTUR */
  const submitUtr = () => {
    if (formState.utr.length !== 12) {
      modalAlert({
        type: 'warning',
        content: `Important reminder: After completingthe UPI transaction, please backfill RefNo ./UTR No./Google Pay : UPI transaction ID/Freecharge: TransactionID (12 digits). lf you do not backfill UTR 100% of the deposit transaction will fail.Please be sure to backfill! I know and continue to pay.`,
      })
    } else {
      indiaH5BindUtr(formState).then(() => {
        modalAlert({
          content: 'Submitted successfully.',
          type: 'success',
        })
        endExpiredTimer()
        startLoopTimer(orderStore.getInfo)
      }
      )
    }
  }

  const { orderInfo, startLoopTimer, endExpiredTimer, expiredCountdown } = useTimer()

  const init = () => {
    startLoopTimer(orderStore.getInfo)
  }
  const tipsShow = ref(false)
  return {
    orderInfo,
    init,
    formState,
    onUtrInput,
    submitUtr,
    expiredCountdown,
    tipsShow,
  }
}