import type { AppRouteRecordRaw } from 'common/router/type'
import { getDeviceType } from 'common/utils/system'
import { useTimer } from '@/hooks/useTimer'
import type { IndiaQr } from '@/types/indiaQr'
import useOrderStore from '@/store/order'
import { getQR } from '@/api/order'
import { reactive, ref } from 'vue'
import { indiaQrBindUtr } from '@/api/indiaQr'
import { modalAlert, modalMessage } from '@/utils/modal'

export const route: AppRouteRecordRaw = {
  path: `/inrqrAnother`,
  component: () => getDeviceType() === 'mobile' ? import('./mobile.vue') : import('./pc.vue'),
}

export const usePageHook = () => {
  const orderStore = useOrderStore()

  const qrCode = ref<string>()
  /** 获取二维码 */
  const getQrCode = () => {
    getQR().then(res => {
      qrCode.value = `data:image/png;base64,${res.qrcode}`
    })
  }

  const formState = reactive({
    utr: '',
  } as IndiaQr)
  const onUtrInput = () => {
    formState.utr = formState.utr.replace(/[^\d]/g, '')
  }
  /** 提交UTUR */
  const submitUtr = () => {
    if (formState.utr.length !== 12) {
      modalAlert({
        type: 'warning',
        content: `Important reminder: After completingthe UPI transaction, please backfill RefNo ./UTR No./Google Pay : UPI transaction ID/Freecharge: TransactionID (12 digits). lf you do not backfill UTR 100% of the deposit transaction will fail.Please be sure to backfill! I know and continue to pay.`,
      })
    } else {
      indiaQrBindUtr(formState).then(() => {
        modalMessage({
          content: 'Submitted successfully.',
          type: 'success',
        })
        endExpiredTimer()
        startLoopTimer(orderStore.getInfo)
      }
      )
    }
  }

  const { orderInfo, startLoopTimer, endExpiredTimer } = useTimer()

  const init = () => {
    startLoopTimer(orderStore.getInfo)
    getQrCode()
  }

  return {
    orderInfo,
    init,
    qrCode,
    formState,
    onUtrInput,
    submitUtr,
  }
}