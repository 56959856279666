import cookie from 'js-cookie'

const prefixKey = 'ss-new_pay'
// token
const tokenKey = `${prefixKey}-token`
export const getToken = () => sessionStorage.getItem(tokenKey) || cookie.get(tokenKey)
export const setToken = (token: string) => {
  sessionStorage.setItem(tokenKey, token)
  cookie.set(tokenKey, token, { expires: 30 })
}
export const removeToken = () => {
  sessionStorage.removeItem(tokenKey)
  cookie.remove(tokenKey)
}

export const removeAll = () => {
  removeToken()
}