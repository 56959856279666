import request from 'common/utils/requests'
import type { AppConfig, CcEvent, Regular } from '@/types/app'

/** 获取产品配置项 */
export const getAppInfo = () =>
  request.get<null, AppConfig>(`/merchant/checkout/counter/config`)

export const ccEvent = (data: CcEvent, token: string) =>
  request.post<CcEvent, null>(`/merchant/cc-event`, data, {
    headers: {
      isToken: false,
      Authorization: `Token ${token}`
    }
  })

export const setRegular = (data: any) =>
  request.post<any, null>(`/merchant/checkout/counter/shared`, data)
