import type { AppRouteRecordRaw } from 'common/router/type'
import { getDeviceType } from 'common/utils/system'
import { useTimer } from '@/hooks/useTimer'
import useOrderStore from '@/store/order'
import { getQR } from '@/api/order'
import { reactive, ref } from 'vue'
import { modalAlert, modalMessage } from '@/utils/modal'

export const route: AppRouteRecordRaw = {
  path: `/rmqr`,
  component: () =>
    getDeviceType() === 'mobile' ? import('./mobile.vue') : import('./pc.vue')
}

export const usePageHook = () => {
  const orderStore = useOrderStore()

  const qrCode = ref<string>()
  /** 获取二维码 */
  const getQrCode = () => {
    getQR().then(res => {
      qrCode.value = `data:image/png;base64,${res.qrcode}`
    })
  }

  const init = () => {
    startLoopTimer(orderStore.getInfo)
    getQrCode()
  }

  const { orderInfo, startLoopTimer, expiredCountdown } = useTimer()

  return {
    orderInfo,
    init,
    expiredCountdown,
    qrCode
  }
}
