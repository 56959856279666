import type { AppRouteRecordRaw } from 'common/router/type'
import { getDeviceType } from 'common/utils/system'
import { useTimer } from '@/hooks/useTimer'
import useOrderStore from '@/store/order'
import type { IndiaQr } from '@/types/indiaQr'
import { getQR } from '@/api/order'
import { reactive, ref } from 'vue'
import { indiaQrBindUpi, indiaQrBindUtr } from '@/api/indiaQr'
import { modalAlert, modalMessage } from '@/utils/modal'
import { env } from 'common/utils/env'

export const route: AppRouteRecordRaw = {
  path: `/notSkipUpi`,
  component: () => getDeviceType() === 'mobile' ? import('./mobile.vue') : import('./pc.vue'),
}

export const usePageHook = () => {
  const orderStore = useOrderStore()
  /** 获取银行logo */
  const getBankLogo = (name: string) => {
    if (env.isUseVite) {
      return new URL(`../../../assets/bank/${name}.png`, import.meta.url).href
    } else {
      return require(`../../../assets/bank/${name}.png`)
    }
  }
  const bankList = [
    { name: `Paytm`, icon: getBankLogo(`paytm`) },
    { name: `PhonePe`, icon: getBankLogo(`phonePe`) },
    { name: `GPay`, icon: getBankLogo(`gPay1`) },
    { name: `BHIM`, icon: getBankLogo(`bhim`) },
    { name: ``, icon: getBankLogo(`india`) },
    { name: ``, icon: getBankLogo(`indiaM`) },
    { name: ``, icon: getBankLogo(`indiaF`) },
  ]

  const qrCode = ref<string>()
  /** 获取二维码 */
  const getQrCode = () => {
    getQR().then(res => {
      qrCode.value = `data:image/png;base64,${res.qrcode}`
    })
  }

  const formState = reactive({
    upi: '',
    utr: '',
  } as IndiaQr)
  /** 提交UPI */
  const submitUpi = () => {
    if (!formState.upi.includes('@')) {
      modalAlert({
        type: 'warning',
        content: `Please enter UPI in the correct format`,
      })
    } else {
      indiaQrBindUpi({ upi: formState.upi }).then((res) => {
        if (res.upi === formState.upi) {
          endExpiredTimer()
          startLoopTimer(orderStore.getInfo)
          getQrCode()
        }
      }
      )
    }
  }

  const onUtrInput = () => {
    formState.utr = formState.utr.replace(/[^\d]/g, '')
  }
  /** 提交UTUR */
  const submitUtr = () => {
    if (formState.utr.length !== 12) {
      modalAlert({
        type: 'warning',
        content: `Important reminder: After completingthe UPI transaction, please backfill RefNo ./UTR No./Google Pay : UPI transaction ID/Freecharge: TransactionID (12 digits). lf you do not backfill UTR 100% of the deposit transaction will fail.Please be sure to backfill! I know and continue to pay.`,
      })
    } else {
      indiaQrBindUtr({ utr: formState.utr }).then(() => {
        modalMessage({
          content: 'Submitted successfully.',
          type: 'success',
        })
        endExpiredTimer()
        startLoopTimer(orderStore.getInfo)
      }
      )
    }
  }

  const { orderInfo, startLoopTimer, endExpiredTimer, expiredCountdown } = useTimer()

  const init = () => {
    startLoopTimer(orderStore.getInfo)
    getQrCode()
  }

  return {
    getBankLogo,
    bankList,
    orderInfo,
    init,
    qrCode,
    formState,
    submitUpi,
    onUtrInput,
    submitUtr,
    expiredCountdown,
  }
}