import type { AppRouteRecordRaw } from 'common/router/type'
import { useTimer } from '@/hooks/useTimer'
import type { IndiaQr } from '@/types/indiaQr'
import useOrderStore from '@/store/order'
import { getQR } from '@/api/order'
import { reactive, ref, h } from 'vue'
import { indiaQrBindUtr } from '@/api/indiaQr'
import { modalAlert, modalMessage } from '@/utils/modal'
import { env } from 'common/utils/env'
import { getDeviceType } from 'common/utils/system'
import { Modal } from 'ant-design-vue'
import localImage from '@/assets/images/indiaQr/findUtr.webp'

export const route: AppRouteRecordRaw = {
  path: `/indiaSpecial`,
  component: () =>
    getDeviceType() === 'mobile' ? import('./mobile.vue') : import('./pc.vue')
}

export const usePageHook = () => {
  const orderStore = useOrderStore()
  /** 获取银行logo */
  const getBankLogo = (name: string) => {
    if (env.isUseVite) {
      return new URL(`../../../assets/bank/${name}.png`, import.meta.url).href
    } else {
      return require(`../../../assets/bank/${name}.png`)
    }
  }

  const qrCode = ref<string>()
  /** 获取二维码 */
  const getQrCode = () => {
    getQR().then(res => {
      qrCode.value = `data:image/png;base64,${res.qrcode}`
    })
  }

  const formState = reactive({
    utr: ''
  } as IndiaQr)
  const onUtrInput = () => {
    formState.utr = formState.utr.replace(/[^\d]/g, '')
  }

  /** 提交UTUR */
  const submitUtr = () => {
    if (formState.utr.length !== 12) {
      Modal.confirm({
        title: 'Tips',
        content: h('div', [
          h(
            'p',
            { style: 'color:red; font-size:16px;' },
            'Important reminder: After completing the UPI transaction, please backfill RefNo ./UTR No./Google Pay : UPI transaction ID/Freecharge: Transaction ID (12 digits). If you do not backfill UTR 100% of the deposit transaction will fail. Please be sure to backfill!'
          ),
          h('br'), // 插入一个换行
          h('img', {
            src: localImage,
            alt: 'Example Image',
            style: { maxWidth: '100%', height: 'auto' }
          })
        ]),
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        centered: true
      })
    } else {
      indiaQrBindUtr(formState).then(() => {
        modalMessage({
          content: 'Submitted successfully.',
          type: 'success'
        })
        endExpiredTimer()
        startLoopTimer(orderStore.getInfo)
      })
    }
  }

  const { orderInfo, startLoopTimer, endExpiredTimer, expiredCountdown } =
    useTimer()

  const init = () => {
    startLoopTimer(orderStore.getInfo)
    getQrCode()
  }

  return {
    getBankLogo,
    orderInfo,
    init,
    qrCode,
    formState,
    onUtrInput,
    submitUtr,
    expiredCountdown
  }
}
