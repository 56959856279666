import type { AppRouteRecordRaw } from 'common/router/type'
import { getDeviceType } from 'common/utils/system'
import useOrderStore from '@/store/order'
import { useTimer } from '@/hooks/useTimer'
import { reactive, ref } from 'vue'
import type { Regular } from '@/types/app'
import { modalAlert } from '@/utils/modal'
import { setRegular } from '@/api/app'
import { getOrderInfo } from '@/api/order'
import { Order } from '@/types/order'
import router from 'common/router'

export const route: AppRouteRecordRaw = {
  path: `/jazzpay`,
  component: () =>
    getDeviceType() === 'mobile' ? import('./mobile.vue') : import('./pc.vue')
}
export const usePageHook = () => {
  const form = ref({
    ex_data: {
      cnic: '',
      phone: '',
      pay_type: ''
    }
  } as Regular)
  const saveLoading = ref(false)
  const timer = ref(0)

  /** 打开轮询 */
  const openPoll = () => {
    timer.value = setInterval(() => {
      getOrderInfos()
    }, 5000)
  }

  const closePoll = () => {
    clearInterval(timer.value)
  }

  const orderInfo = ref({} as Order)

  const getOrderInfos = () => {
    getOrderInfo().then(res => {
      orderInfo.value = res
      if (res.pkr_response) {
        if (
          res.pkr_response.status === 'pedding' &&
          res.pkr_response.message === '' &&
          res.pkr_response.url === ''
        ) {
          saveLoading.value = true
          openPoll()
        } else if (
          res.pkr_response.status === 'failed' &&
          res.pkr_response.message
        ) {
          modalAlert({
            type: 'error',
            content: `${res.pkr_response.message}`
          })
          closePoll()
          router.replace({ name: 'expired' })
        } else if (res.pkr_response.status === 'success') {
          window.open(res.pkr_response.url, '_self')
          saveLoading.value = false
          closePoll()
        }
      }
    })
  }

  const save = () => {
    if (form.value.ex_data.phone&&form.value.ex_data.cnic) {
      saveLoading.value = true
      const jsonObj = {
        phone: form.value.ex_data.phone,
        cnic: form.value.ex_data.cnic,
        pay_type: 'jazzcash'
      }
      const data = {
        ex_data: JSON.stringify(jsonObj)
      }
      setRegular(data)
        .then(() => {
          getOrderInfos()
          saveLoading.value = false
        })
        .catch(() => {
          getOrderInfos()
        })
    } else {
      modalAlert({
        type: 'error',
        content: `No information provided`
      })
    }
  }

  const init = () => {
    getOrderInfos()
  }

  return {
    orderInfo,
    form,
    save,
    saveLoading,
    init
  }
}
