import dayjs from 'dayjs'
import 'dayjs/locale/zh-tw'
import 'dayjs/locale/en'
import utcPlugin from 'dayjs/plugin/utc'
import timezonePlugin from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import type { DatetimeType, DayValue, DayFormat, DatetimeDataType } from './type'

type Language = 'zhTW' | 'enUS'
const langs: Record<Language, string> = {
  enUS: 'en',
  zhTW: 'zh-tw'
}

/**
 *  初始化dayjs插件
 * @param key 语音环境
 */
export const initDayjsUtils = (key: Language = 'enUS') => {
  dayjs.extend(utcPlugin)
  dayjs.extend(timezonePlugin)
  dayjs.extend(relativeTime)
  setDayjsLocale(key)
}

/**
 * 修改dayjs语音环境
 * @param key 
 */
export const setDayjsLocale = (key: Language) => {
  dayjs.locale(langs[key])
}

/**
 * 默認格式化模板
 */
export const defaultValueFormat: Record<DatetimeType, DayFormat> = {
  time: `HH:mm:ss`,
  date: `YYYY-MM-DD`,
  datetime: `YYYY-MM-DD HH:mm:ss`,
  year: `YYYY`,
  month: `YYYY-MM`,
  week: `YYYY-MM-DD`
}

/**
 * 根据时区和格式化模板将10位数的时间戳number转化为字符串string
 * @param unix10 10位数的时间戳，秒
 * @param dayFormat 格式化模板
 * @returns 
 */
export const unix10ToString = (unix10: number, dayFormat: DayFormat) => {
  if (unix10.toString().length !== 10) {
    return ''
  }
  return dayjs.unix(unix10).format(dayFormat)
}

/**
 * 根据时区和格式化模板将13位数的时间戳number转化为字符串string
 * @param unix13 13位数的时间戳，秒
 * @param dayFormat 格式化模板
 * @returns 
 */
export const unix13ToString = (unix13: number, dayFormat: DayFormat) => {
  if (unix13.toString().length !== 13) {
    return ''
  }
  return dayjs(unix13).format(dayFormat)
}

/**
 * 根据时区和格式化模板转化为字符串string
 * @param day
 * @param dayFormat 格式化模板
 * @returns 
 */
export const dayToString = (day: DayValue, dayFormat: DayFormat) => {
  if (!day) {
    return null
  }
  if (typeof day === 'string') {
    return dayjs(day).format(dayFormat)
  }
  return unix10ToString(day, dayFormat) || unix13ToString(day, dayFormat)
}

/**
 * 根据时区将字符串string转化10位数的时间戳number
 * @param day 
 * @returns 
 */
export const stringToUnix10 = (day: string) => {
  return dayjs(day).unix() || null
}

/**
 * 根据将字符串string转化13位数的时间戳number
 * @param day 
 * @returns 
 */
export const stringToUnix13 = (day: string) => {
  return dayjs(day).valueOf() || null
}

/**
 * 根据時間戳位數转化时间戳number
 * @param day 
 * @param unixLen 
 * @returns 
 */
export const dayToUnix = (day: DayValue, unixLen: DatetimeDataType) => {
  const len = day.toString().length
  const isString = typeof day === 'string'
  if (!day || (!isString && ![10, 13].includes(len))) {
    return null
  }
  if (isString) {
    return unixLen === 'unix10' ? stringToUnix10(day) : stringToUnix13(day)
  }
  switch (unixLen) {
    case 'unix10':
      return len === 10 ? day : day / 1000
    case 'unix13':
      return len === 13 ? day : day * 1000
  }
  return null
}


/**
 * 根据时区获取日期所在的周
 * @param day 
 * @param toType 返回的数据类型格式
 * @param format 格式化模板
 * @returns [开始日期, 结束日期]
 */
export const dayToWeek = (day: DayValue, toType: DatetimeDataType, format: DayFormat = 'YYYY-MM-DD'): [DayValue | null, DayValue | null] => {
  const _day = dayjs(dayToString(day, format))
  const _start = _day.startOf('week').format(format)
  const _end = _day.endOf('week').format(format)
  if (toType === 'string') {
    return [_start, _end]
  }
  return [dayToUnix(_start, toType), dayToUnix(_end, toType)]
}

/**
 * 倒计时
 * @param endDay 
 * @returns 
 */
export const getDayDiff = (endDay: DayValue) => {
  const _endDay = typeof endDay === 'string' ? endDay : `${endDay}`.length < 13 ? endDay * 1000 : endDay
  const start = dayjs(dayjs())
  const end = dayjs(_endDay)
  const h = end.diff(start, 'h')
  const m = end.diff(start, 'm') % 60
  const s = end.diff(start, 's') % 60
  const format = (v: number) => { return v < 10 ? `0${v}` : v }
  return h + m + s <= 0 ? '' : `${format(h)}:${format(m)}:${format(s)}`
}