import type { AppRouteRecordRaw } from 'common/router/type'
import { getDeviceType } from 'common/utils/system'
import { useTimer } from '@/hooks/useTimer'
import type { IndiaH5 } from '@/types/indiaH5'
import useOrderStore from '@/store/order'
import { getQR } from '@/api/order'
import { reactive, ref } from 'vue'
import { indiaH5BindUtr } from '@/api/indiaH5'
import { modalAlert, modalMessage } from '@/utils/modal'
import { env } from 'common/utils/env'

export const route: AppRouteRecordRaw = {
  path: `/new_indiah5`,
  component: () => getDeviceType() === 'mobile' ? import('./mobile.vue') : import('./pc.vue'),
}

export const usePageHook = () => {
  const orderStore = useOrderStore()
  /** 获取银行logo */
  const getBankLogo = (name: string) => {
    if (env.isUseVite) {
      return new URL(`../../../assets/bank/${name}.png`, import.meta.url).href
    } else {
      return require(`../../../assets/bank/${name}.png`)
    }
  }
  const bankList = [
    { name: `Paytm`, icon: getBankLogo(`paytm`) },
    { name: `PhonePe`, icon: getBankLogo(`phonePe`) },
    { name: `GPay`, icon: getBankLogo(`gPay1`) },
    { name: `BHIM`, icon: getBankLogo(`bhim`) },
    { name: ``, icon: getBankLogo(`india`) },
    { name: ``, icon: getBankLogo(`indiaM`) },
    { name: ``, icon: getBankLogo(`indiaF`) },
  ]

  const qrCode = ref<string>()
  /** 获取二维码 */
  const getQrCode = () => {
    getQR().then(res => {
      qrCode.value = `data:image/png;base64,${res.qrcode}`
    })
  }

  const formState = reactive({
    utr: '',
  } as IndiaH5)
  const onUtrInput = () => {
    formState.utr = formState.utr.replace(/[^\d]/g, '')
  }
  /** 提交UTUR */
  const submitUtr = () => {
    if (formState.utr.length !== 12) {
      modalAlert({
        type: 'warning',
        content: `Important reminder: After completingthe UPI transaction, please backfill RefNo ./UTR No./Google Pay : UPI transaction ID/Freecharge: TransactionID (12 digits). lf you do not backfill UTR 100% of the deposit transaction will fail.Please be sure to backfill! I know and continue to pay.`,
      })
    } else {
      indiaH5BindUtr(formState).then(() => {
        modalMessage({
          content: 'Submitted successfully.',
          type: 'success',
        })
        endExpiredTimer()
        startLoopTimer(orderStore.getInfo)
      }
      )
    }
  }

  const { orderInfo, startLoopTimer, endExpiredTimer, expiredCountdown } = useTimer()

  const init = () => {
    startLoopTimer(orderStore.getInfo)
    if (getDeviceType() === 'pc') {
      getQrCode()
    }
  }

  return {
    getBankLogo,
    orderInfo,
    init,
    bankList,
    qrCode,
    formState,
    onUtrInput,
    submitUtr,
    expiredCountdown,
  }
}