/** 当前环境是否为vite */
const isUseVite = process.env.npm_lifecycle_script || false

/** 环境变量 */
const NODE_ENV = import.meta.env ? import.meta.env.MODE : process.env.NODE_ENV
/** 是否为生产环境 */
const isBuild = NODE_ENV === 'production'
/** 默认语言环境 */
const VUE_APP_DEFAULT_I18N = import.meta.env ? import.meta.env.VUE_APP_DEFAULT_I18N : process.env.VUE_APP_DEFAULT_I18N
/** 请求域名 */
const VUE_APP_BASE_API = import.meta.env ? import.meta.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API

/** 是否启用sentry */
const openSentry = (import.meta.env ? import.meta.env.VUE_APP_SENTRY_SWITCH : process.env.VUE_APP_SENTRY_SWITCH) === 'true'
/** sentry DSN */
const VUE_APP_SENTRY_DSN = import.meta.env ? import.meta.env.VUE_APP_SENTRY_DSN : process.env.VUE_APP_SENTRY_DSN
/** sentry token */
const VUE_APP_SENTRY_TOKEN = import.meta.env ? import.meta.env.VUE_APP_SENTRY_TOKEN : process.env.VUE_APP_SENTRY_TOKEN

export const env = {
  isUseVite,
  isBuild,
  NODE_ENV,
  VUE_APP_BASE_API,
  VUE_APP_DEFAULT_I18N,
  openSentry,
  VUE_APP_SENTRY_DSN,
  VUE_APP_SENTRY_TOKEN,
}