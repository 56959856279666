import { createRouter, createWebHistory } from 'vue-router'
import Error404 from 'components/error/404.vue'
import { getInterceptorRouter } from './interceptor'
import type { AppRouteRecordRaw } from './type'

export const defaultRoutes: AppRouteRecordRaw[] = [
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: Error404
  },
]

const router = createRouter({
  history: createWebHistory('/'),
  routes: defaultRoutes
})

export default getInterceptorRouter(router)
