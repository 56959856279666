import { type ModalFuncProps, message, Modal, MessageArgsProps } from 'ant-design-vue'
import { pxToViewport } from 'common/components/utils/stylePxToViewport'
import { getDeviceType } from 'common/utils/system'
import { h } from 'vue'


type ModalType = 'info' | 'success' | 'warning' | 'error' | 'loading'
type ModalParams = {
  type: ModalType
} & ModalFuncProps

export const modalAlert = (options: ModalParams) => {
  const config: ModalFuncProps = {
    width: getDeviceType() === 'mobile' ? 340 : 585,
    okText: options.okText || 'Continue',
    onOk: options.onOk,
    okButtonProps: options.okButtonProps || {
      type: 'text',
      size: 'middle',
    },
    cancelText: options.cancelText || 'Cancel',
    cancelButtonProps: options.cancelButtonProps || {
      type: 'text',
      size: 'middle',
    },
    centered: true,
    title: `Tips`,
    content: typeof options.content === 'string' ? () => {
      return h('p', { style: { fontSize: pxToViewport(13) } }, options.content as string)
    } : options.content
  }
  Modal[options.type](config)
}

export const modalMessage = (options: ModalParams) => {
  const config: MessageArgsProps = {
    duration: 3,
    content: typeof options.content === 'string' ? () => {
      return h('span', { style: { fontSize: pxToViewport(14) } }, options.content as string)
    } : options.content
  }
  message[options.type](config)
}